import $ from "jquery";

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

$(".slider-container").on("mousedown mousemove touchstart touchmove", function(ev) {
    const {xpos, pressed} = decodeEvent(ev.originalEvent);
    if(!pressed) {
        return;
    }

    const $slider = $(this);
    const sliderRect = $slider.get(0).getBoundingClientRect();
    const xpct = (xpos-sliderRect.left)/sliderRect.width;
    updateSliderPos($slider, clamp(xpct*100, 0, 100));
    //ev.preventDefault();
});

function decodeEvent(domEvent) {
    if(domEvent.touches && domEvent.touches.length > 0) {
        return {xpos: domEvent.touches[0].clientX, pressed:true};
    } else {
        return {xpos: domEvent.clientX, pressed: domEvent.buttons>0};
    }
}

function updateSliderPos($slider, sliderPos) {
    // Update the width of the foreground image
    $slider.find('.foreground-img').css('width', `${sliderPos}%`)
    // Update the position of the slider button
    $slider.find('.slider-button').css('left', `calc(${sliderPos}% - 17px)`)
}
